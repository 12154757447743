// components
import Container from "src/components/Container";

const Sponsor = () => {
  return (
    <div className="ld-sponsor">
      <Container className="nav-container">
        <div className="brand-list">
          <img
            className="swiper-slide-image"
            src="/statistic/assets/landing/sponsors/staking1.png"
            alt="gray-logo-crypto-5"
          />
          <img
            className="swiper-slide-image"
            src="/statistic/assets/landing/sponsors/validated1.png"
            alt="gray-logo-crypto-2"
          />
          <img
            className="swiper-slide-image"
            src="/statistic/assets/landing/sponsors/utility1.png"
            alt="gray-logo-crypto-1"
          />
          <img
            className="swiper-slide-image"
            src="/statistic/assets/landing/sponsors/decentralized2.png"
            alt="gray-logo-crypto-3"
          />
          <img
            className="swiper-slide-image"
            src="/statistic/assets/landing/sponsors/collateralized2.png"
            alt="gray-logo-crypto-4"
          />
          <img
            className="swiper-slide-image"
            src="/statistic/assets/landing/sponsors/issuance1.png"
            alt="gray-logo-crypto-5"
          />
        </div>
      </Container>
    </div>
  );
};

export default Sponsor;
