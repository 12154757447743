import React, { MouseEventHandler } from "react";

interface ParentProps {
  children: React.ReactNode;
  isOpen: Boolean;
  className?: string;
  onClose: MouseEventHandler<HTMLDivElement>;
}

const Modal = ({ children, isOpen, className, onClose }: ParentProps) => {
  return (
    <div
      className={`modal ${className} ${isOpen ? "show-modal" : "hide-modal"}`}
    >
      <div className="modal-container">
        <div className="close" onClick={onClose}>
          &#10006;
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
