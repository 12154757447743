import Hero from "./white-creator-components/Hero";
import Sponsor from "./white-creator-components/Sponsor";
import About from "./white-creator-components/About";
import Portfolio from "./white-creator-components/Portfolio";
import Investing from "./white-creator-components/Investing";
import Download from "./white-creator-components/Download";
import World from "./white-creator-components/World";
import Guide from "./white-creator-components/Guide";
import Comment from "./white-creator-components/Comment";

const Landing = () => {
  return (
    <div className="landing" id="top">
      <Hero />
      <Sponsor />
      <About />
      <Portfolio />
      <Investing />
      <Download />
      <World />
      <Guide />
      <Comment />
    </div>
  );
};

export default Landing;
