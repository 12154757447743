import React from "react";

interface CardProps {
  children?: React.ReactNode;
  className?: string;
  rest?: string;
}

const Card = ({ className, children, ...rest }: CardProps) => {
  return (
    <div {...rest} className={`card ${className}`}>
      {children}
    </div>
  );
};

export default Card;
