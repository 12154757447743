import { MouseEventHandler, useState, useEffect } from "react";

// hooks
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import windowSize from "src/hooks/useWindowSize";
import { getSigner } from "src/utils";

// utils
import { shorter, TokenType } from "src/utils";
import StandardTokenTestABI from "src/utils/abis/BridgeToken.json";

// components
import Modal from "src/components/Modal";
import Button from "src/components/Button";
import Loader from "src/components/Loader";

// third party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toast from "react-hot-toast";

let standardABI = StandardTokenTestABI.abi;
let standardBytecode = StandardTokenTestABI.bytecode;

interface ParentProps {
  isShow: Boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
}
interface TokenInfoProps {
  tokenName: string;
  tokenSymbol: string;
  tokenSupply: number;
  tokenDecimal: number;
  bridgerAddr: string;
}

const BepToken = ({ isShow, onClose }: ParentProps) => {
  const validationSchema = Yup.object().shape({
    tokenName: Yup.string().required("Token name is required"),
    tokenSymbol: Yup.string().required("Token symbol is required"),
    tokenSupply: Yup.number().required("Token supply is required"),
    tokenDecimal: Yup.number().required("Token Decimal is required"),
    bridgerAddr: Yup.string().required("Bridger address is required"),
  });
  const initialState: TokenInfoProps = {
    tokenName: "",
    tokenSymbol: "",
    tokenSupply: 0,
    tokenDecimal: 18,
    bridgerAddr: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TokenInfoProps>({
    resolver: yupResolver(validationSchema),
  });
  const [tokenInfo, setTokenInfo] = useState<TokenInfoProps>({
    ...initialState,
  });
  const [deployAddr, setDeployAddr] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { account, chainId } = useWeb3React();
  const { width } = windowSize();
  const Network = {
    1: { name: "Ethereum Mainnet", url: "https://etherscan.io/token/" },
    3: { name: "Ropsten Testnet", url: "https://etherscan.io/token/" },
    4: { name: "Rinkeby Testnet", url: "https://etherscan.io/token/" },
    5: { name: "Goerli Testnet", url: "https://goerli.etherscan.io/token/" },
    56: { name: "BSC Mainnet", url: "https://bscscan.com/token/" },
    97: { name: "BSC Testnet", url: "https://testnet.bscscan.com/token/" },
    11155111: {
      name: "Seplia Testnet",
      url: "https://sepolia.etherscan.io/token/",
    },
  };
  const AbiInfo = {
    56: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    97: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    1: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    5: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    11155111: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
  };
  const bridgeAddressInfo = {
    1: "0x5A23d0075862435C5fA4658e499d72C6Acf55930",
    11155111: "0xF62B037DaA454769e70dE9EceE1aB7632D223d21",
    56: "0x5A23d0075862435C5fA4658e499d72C6Acf55930",
    97: "0x06f0ddb0E8306e380AC4AA6547C2b49653Acee1c",
  };

  useEffect(() => {
    if (chainId == null) return;

    onReset();
    setValue("bridgerAddr", bridgeAddressInfo[chainId]);
    setTokenInfo((prevState) => ({
      ...prevState,
      bridgerAddr: bridgeAddressInfo[chainId],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, chainId]);

  useEffect(() => {
    if (chainId) {
      standardABI = AbiInfo[chainId]["abi"];
      standardBytecode = AbiInfo[chainId]["bytecode"];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account]);

  function onChange(e: { target: { name: any; value: any } }) {
    const { name, value } = e.target;
    setTokenInfo({
      ...tokenInfo,
      [name]: value,
    });
  }
  async function onSubmit(data: TokenInfoProps) {
    if (!account) {
      toast.error("Connect your metamask to mint");
      return;
    }
    try {
      setIsLoading(true);
      const signer = await getSigner();
      const factory = new ethers.ContractFactory(
        standardABI,
        standardBytecode,
        signer
      );
      const contract = await factory.deploy(
        data.tokenName,
        data.tokenSymbol,
        ethers.utils.parseEther(data.tokenSupply.toString()),
        data.bridgerAddr
      );
      setDeployAddr(contract.address);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }
  function onReset() {
    setTokenInfo(initialState);
    reset();
  }

  return (
    <Modal isOpen={isShow} className="creator-modal" onClose={onClose}>
      <div className={`overlay ${isLoading ? "show-overlay" : "hide-overlay"}`}>
        <Loader />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="heading">
          Create Your {TokenType[chainId]} Bridge Token
        </h1>
        {deployAddr && !isLoading && chainId ? (
          <div className="content">
            <h2 className="heading">Successfully deployed!</h2>
            <a
              href={Network[chainId]["url"] + deployAddr}
              className="heading"
              target="_blank"
              rel="noreferrer"
            >
              <span>Click here to check your token</span>
            </a>
          </div>
        ) : (
          <>
            <div className="content">
              <div className="row">
                <label htmlFor="">Selected Account *</label>
                <span>
                  {account
                    ? width < 640
                      ? shorter(account)
                      : account
                    : "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Current Network *</label>
                <span>
                  {chainId
                    ? Network[chainId]["name"]
                    : "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Name *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.tokenName}
                    {...register("tokenName")}
                    name="tokenName"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenName?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Symbol *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.tokenSymbol}
                    {...register("tokenSymbol")}
                    name="tokenSymbol"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenSymbol?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Initial Supply *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tokenSupply}
                    {...register("tokenSupply")}
                    name="tokenSupply"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenSupply?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Decimals *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tokenDecimal}
                    {...register("tokenDecimal")}
                    name="tokenDecimal"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenDecimal?.message}
                  </div>
                </div>
              </div>
              <div className="row hidden">
                <label htmlFor="">Bridger Address *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.bridgerAddr}
                    {...register("bridgerAddr")}
                    name="bridgerAddr"
                    disabled
                    style={{
                      color: "#fff",
                    }}
                  />
                  <div className="invalid-feedback">
                    {errors.bridgerAddr?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <Button variant="primary" submit>
                Mint
              </Button>
              <Button variant="fill" onClick={onReset}>
                Reset
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default BepToken;
