import { Link } from "react-router-dom";

// utils
import { fadeInRight, fadeInUp } from "src/utils/keyframes";
import { TokenType } from "src/utils";

// hooks
import { useModalShow } from "src/hooks/useModalShow";
import { useWeb3React } from "@web3-react/core";

// third party
import { Reveal } from "react-awesome-reveal";

// components
import Container from "src/components/Container";
import BepToken from "./BepToken";

const Investing = () => {
  const { chainId } = useWeb3React();
  const { isShow, getBepToken, closeModal } = useModalShow();

  return (
    <>
      <div className="ld-investing" id="investing">
        <Container className="nav-container">
          <div className="description">
            <div className="nav-desc">
              <Reveal keyframes={fadeInUp} delay={100} triggerOnce>
                <div className="heading">ABOUT APP</div>
              </Reveal>
              <Reveal keyframes={fadeInUp} delay={200} triggerOnce>
                <div className="content">
                  Fully Automated {TokenType[chainId]} Token Development
                </div>
              </Reveal>
              <Reveal keyframes={fadeInUp} delay={300} triggerOnce>
                <div className="desc">
                  Your original {TokenType[chainId]} token is automatically
                  created and can be offered at a low price.
                </div>
              </Reveal>
              <Reveal keyframes={fadeInUp} delay={400} triggerOnce>
                <div className="link">
                  <Link to="#" onClick={getBepToken}>
                    <span className="icon">
                      <i
                        aria-hidden="true"
                        className="fas fa-external-link-alt"
                      ></i>
                    </span>
                    <span className="title">
                      GET {TokenType[chainId]} TOKEN
                    </span>
                  </Link>
                </div>
              </Reveal>
            </div>
          </div>
          <div className="img-group">
            <Reveal keyframes={fadeInRight} triggerOnce>
              <div className="line-back">
                <img
                  width="400"
                  src="https://toka.b-cdn.net/wp-content/uploads/2022/04/krjfr.png"
                  className="star"
                  alt=""
                  loading="lazy"
                  sizes="(max-width: 400px) 100vw, 400px"
                />
                <img
                  src="/statistic/assets/landing/binancelogo2.png"
                  className="man"
                  alt=""
                  loading="lazy"
                  sizes="(max-width: 700px) 100vw, 700px"
                />
              </div>
            </Reveal>
          </div>
        </Container>
      </div>
      <BepToken isShow={isShow} onClose={closeModal} />
    </>
  );
};

export default Investing;
