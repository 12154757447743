import { useState, createContext } from "react";

type SidebarContext = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToogle] = useState<boolean>(true);
  const toggleSidebar = () => {
    setSidebarToogle(!sidebarToggle);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
