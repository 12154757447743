import { useRoutes } from "react-router-dom";
import { useEagerConnect } from "src/hooks/useEagerConnect";
import MainLayout from "./pages/layouts/MainLayout";
import Landing from "./pages/white-creator/Landing";

function App() {
  useEagerConnect();

  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Landing />,
        },
      ],
    },
  ]);

  return routes;
}

export default App;
